import { createSlice } from "@reduxjs/toolkit";
import GraphConfig from "../../reactflow/GraphConfig";

//
// Util Functions
// ----------------------------------------------------------------------------
const { NODE, EDGE, PANEL } = GraphConfig.ReactFlowElements;

// Intital state
const initialState = {
  // Element select Map
  // elementSelectMap ==>
  // {
  //  "NODE": {
  //    "mdncn": {
  //      "id": "mdncn",
  //      "select": false
  //    },
  //
  //   "mdncn": {
  //      "id": "mdncn",
  //      "select": false
  //    }
  //  }
  //}
  elementSelectMap: {
    [NODE]: {},
    [EDGE]: {},
    [PANEL]: {},
  },
};

// Node Slice
const elementSlice = createSlice({
  // Name
  name: "Element",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    selectOnlyOneElement: (state, action) => {
      const { elementId, elementType } = action.payload;

      // First reset to initial state
      //TODO: state.elementSelectMap = initialState.elementSelectMap not working..
      state.elementSelectMap = {
        [NODE]: {},
        [EDGE]: {},
        [PANEL]: {},
      };
      state.elementSelectMap[elementType][elementId] = { id: elementId, selected: true };
    },

    selectElement: (state, action) => {
      const { elementId, elementType } = action.payload;
      state.elementSelectMap[elementType][elementId] = { id: elementId, selected: true };
    },

    unSelectElement: (state, action) => {
      const { elementId, elementType } = action.payload;
      delete state.elementSelectMap[elementType][elementId];
    },
  },
});

// Reducer
export const ElementReducer = elementSlice.reducer;

// Actions
export const ElementActions = elementSlice.actions;
