import React from "react";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";

//
import GraphEquationsCard from "./card/GraphEquationsCard";
import GraphParametersCard from "./card/GraphParametersCard";

//
// Component
// ----------------------------------------------------------------------------

//
export default function SectionGraphInfo({ graphId, parameters = [], equations = [], variables = [] }) {
  return (
    <>
      <div className="sec-info p-3">
        <h6 className="px-1 mb-2 d-flex justify-content-between">
          <span>Graph</span>
          <small className="text-secondary"></small>
        </h6>
      </div>

      {/** Node Info */}
      <div className="sec-info">
        <GraphParametersCard collapsed={false} graphId={graphId} parameters={parameters} parameterVarPrefix={""} />
      </div>

      <div className="sec-info">
        <GraphEquationsCard collapsed={false} graphId={graphId} equations={equations} variables={variables} />
      </div>

      <div className="sec-info d-none">
        <CardCollapsible header={"Data"} collapsed={false} className="pb-1">
          {/** */}
        </CardCollapsible>
      </div>
    </>
  );
}
