import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { EquationActions } from "../../../../redux-slice/graph/equationSlice";

// Constants
import { ICON_SIZE } from "../../../../constants/GeneralConstants";

// Components
import CardCollapsible from "../../../../components/card-collapsible/CardCollapsible";
import Icon from "../../../../components/icon/Icon";

// Sections
import GraphEquationModal from "../modal/GraphEquationModal";
import Button from "../../../../components/button/Button";
import Loader from "../../../../components/loader/Loader";

// Page Components
function EditAndDeleteIcon({ equation, openEquationModal, deleteNodeEquation }) {
  const deleteEquationLoading = useSelector((state) => state.equation.deleteEquationLoading);
  const equationIdToBeDeleted = useSelector((state) => state.equation.equationIdToBeDeleted);

  if (deleteEquationLoading && equationIdToBeDeleted === equation.id) {
    return <Loader />;
  }

  return (
    <span>
      <Icon
        className="me-2"
        onClick={() => openEquationModal(equation)}
        iconName="pencil"
        size={ICON_SIZE.SM}
        color="gold"
        strokeWidth={3}
        title="Click to edit"
      />

      <Icon
        onClick={() => deleteNodeEquation(equation)}
        iconName="circleX"
        size={ICON_SIZE.SM}
        color="red"
        strokeWidth={3}
        title="Click to delete"
      />
    </span>
  );
}

export default function GraphEquationsCard({ collapsed, graphId, nodeUid, equations = [], variables = [] }) {
  // dispatch
  const dispatch = useDispatch();

  // Component State
  const [selectedEquation, setSelectedEquation] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Equation Delete
  function deleteNodeEquation(equationObj) {
    // Dispatch Action
    dispatch(EquationActions.deleteEquation({ equationObj, graphId }));

    // update state
    setSelectedEquation({});
  }

  function openEquationModal(eq = {}) {
    setSelectedEquation(eq);
    setIsModalOpen(true);
  }

  function closeEquationModal() {
    setIsModalOpen(false);
  }

  // Form onSubmit
  function onFormSubmit({ name, lhs, rhs, operator }) {
    // Equation Object
    const equationObj = {
      ...selectedEquation,
      graphId,
      nodeUid,
      name,
      lhs,
      rhs,
      operator,
    };

    console.log("equationObj in onFormSubmit :", equationObj);

    const equationId = selectedEquation?.id;

    // Dispatch Action
    const isUpdate = equationId !== undefined;
    if (isUpdate) {
      dispatch(EquationActions.updateEquation({ equationId, equationObj, setIsModalOpen }));
    } else {
      dispatch(EquationActions.addEquation({ equationObj, setIsModalOpen }));
    }
  }

  //
  return (
    <>
      <CardCollapsible header={"Equations"} collapsed={collapsed} className="pb-1" id="card-sel-node-eqns">
        <>
          <ul className="list-unstyled list-graph-elems">
            {equations.map((eq) => {
              const { id = "", name = "", text = "", isGenerated = false } = eq || {};

              //
              return (
                <li key={`eqn-${id}`}>
                  <span title={name}>{text}</span>

                  <EditAndDeleteIcon
                    equation={eq}
                    openEquationModal={() => openEquationModal(eq)}
                    deleteNodeEquation={() => deleteNodeEquation(eq)}
                  />
                </li>
              );
            })}
          </ul>
          <div>
            <Button className="btn btn-xs btn-outline-secondary" onClick={() => openEquationModal()}>
              Add Equation
            </Button>
          </div>
        </>
      </CardCollapsible>

      <GraphEquationModal
        isModalOpen={isModalOpen}
        closeModal={closeEquationModal}
        graphId={graphId}
        nodeUid={nodeUid}
        equation={selectedEquation}
        variables={variables}
        onSave={onFormSubmit}
      />
    </>
  );
}
