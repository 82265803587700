import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { setErrorInfo } from "../../app/error/ErrorActions";
import { NodeActions } from "../../redux-slice/graph/nodeSlice";
import { GraphActions } from "../../redux-slice/graph/graphSlice";

// Service
import NodeService from "../../services/graph/nodeService";

// Create New Node
export function* createNewNode(action) {
  const { graphId, node } = action.payload;

  try {
    const { id, type, position, data = {} } = node;
    const nodeObj = {
      uid: id,
      type: type.toUpperCase(),
      name: data.name,
      symbol: data.symbol,
      posX: position.x,
      posY: position.y,
      style: {},
    };

    // Invoke API
    const newNode = yield NodeService.createNode(graphId, nodeObj);

    yield put(NodeActions.createNodeSuccess({ nodeResponse: newNode, node }));
  } catch (error) {
    yield put(NodeActions.createNodeFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Node Information
export function* updateNodeInfo(action) {
  const { graphId, nodeUid, nodeInfo } = action.payload;

  try {
    yield NodeService.updateNodeInfo(graphId, nodeUid, nodeInfo);
    yield put(NodeActions.updateNodeInfoSuccess({ graphId, nodeUid, nodeInfo }));
  } catch (error) {
    yield put(NodeActions.updateNodeInfoFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Node Style
export function* updateNodeStyle(action) {
  const { graphId, nodeUid, styleObj } = action.payload;

  try {
    yield NodeService.updateNodeStyle(graphId, nodeUid, styleObj);

    yield put(NodeActions.updateNodeStyleSuccess({ graphId, nodeUid, styleObj }));
  } catch (error) {
    yield put(NodeActions.updateNodeStyleFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Node Position
export function* updateNodePosition(action) {
  const { graphId, nodeUid, x, y } = action.payload;

  try {
    // Round off Positions
    const posX = Math.round(x);
    const posY = Math.round(y);

    // Invoke API
    yield NodeService.updateNodePosition(graphId, nodeUid, posX, posY);

    yield put(NodeActions.updateNodePositionSuccess({ graphId, nodeUid, x, y }));
  } catch (error) {
    yield put(NodeActions.updateNodePositionFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Node Equation Generation Status
export function* updateNodeEqnGenStatus(action) {
  const { graphId, nodeUid, generateEqns } = action.payload;

  try {
    // Invoke API
    yield NodeService.updateNodeEqnGenStatus(graphId, nodeUid, generateEqns);

    yield put(NodeActions.updateNodeEqnGenStatusSuccess({ graphId, nodeId: nodeUid, generateEqns }));
  } catch (error) {
    yield put(NodeActions.updateNodeEqnGenStatusFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Delete Node
export function* deleteNode(action) {
  const { graphId, nodeUid } = action.payload;

  try {
    yield NodeService.deleteNode(graphId, nodeUid);

    yield put(NodeActions.deleteNodeSuccess({ graphId, nodeId: nodeUid }));
  } catch (error) {
    yield put(NodeActions.deleteNodeFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Export
export default function* root() {
  yield all([
    takeLatest(NodeActions.createNode.type, createNewNode),

    //
    takeLatest(NodeActions.updateNodeInfo.type, updateNodeInfo),
    takeLatest(NodeActions.updateNodeStyle.type, updateNodeStyle),
    takeLatest(NodeActions.updateNodePosition.type, updateNodePosition),
    takeLatest(NodeActions.updateNodeEqnGenStatus.type, updateNodeEqnGenStatus),

    //
    takeLatest(NodeActions.deleteNode.type, deleteNode),
  ]);
}
