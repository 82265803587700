import React, { memo, useCallback, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useUpdateNodeInternals } from "reactflow";

// Actions
import { NodeActions } from "../../redux-slice/graph/nodeSlice.js";

// Components
import InlineEdit from "../../components/inline-edit/InlineEdit.jsx";

// Constant and Utils
import HandlesCont from "../connection/HandlesCont.jsx";
import GraphUtil from "../GraphUtil";

//
// Constants
const PX_TOP_OFFSET = 35;
const PX_SPACING = 30;

//
// ControlVolumeNode
// ----------------------------------------------------------------------------

function ControlVolumeNode({ id, type, selected, data = {} }) {
  const dispatch = useDispatch();

  // Page Params
  const params = useParams();
  const { projectId } = params;
  const graphId = projectId;

  // Handles
  const { sourceHandleIds, targetHandleIds } = useMemo(() => {
    const { handles = {} } = data || {};
    const sourceHandleIds = handles.source || [];
    const targetHandleIds = handles.target || [];

    return { sourceHandleIds, targetHandleIds };
  }, [data]);

  const sourceHandlesCount = sourceHandleIds.length;
  const targetHandlesCount = targetHandleIds.length;

  // Parameters
  const parametersMap = data.parametersMap || {};
  const parameters = Object.values(parametersMap);

  /**
   * This function is used to update the internal state of a node in React Flow.
   * Since we manage our own state to update React Flow internals, calling this function
   * ensures that React Flow synchronizes with the latest changes in the node's dimensions,
   * position, or handles.
   */
  const updateNodeInternals = useUpdateNodeInternals();

  //
  // Functions

  const estimateBodyHeight = useCallback(() => {
    // Update Body Height
    const bodyHeightUnits = sourceHandlesCount > targetHandlesCount ? sourceHandlesCount : targetHandlesCount;
    const newBodyHeight = (bodyHeightUnits + 1) * PX_SPACING;

    return newBodyHeight;
  }, [sourceHandlesCount, targetHandlesCount]);

  // Component State
  const [bodyHeight, setBodyHeight] = useState(estimateBodyHeight());

  const addHandle = useCallback(
    (isInput) => {
      // Get HandleIds
      const handleIds = isInput ? targetHandleIds : sourceHandleIds;

      // Handles Array
      const handleCnt = handleIds.length;
      const lastHandleId = handleCnt === 0 ? "" : handleIds[handleCnt - 1];
      const nextHandleId = GraphUtil.nextHandleId(isInput, lastHandleId);

      const updatedHandleIds = [...handleIds, nextHandleId];
      const handleIdsObject = { source: sourceHandleIds, target: targetHandleIds };

      if (isInput) {
        handleIdsObject.target = updatedHandleIds;
      } else {
        handleIdsObject.source = updatedHandleIds;
      }

      updateNodeInternals(id);

      // Body Height
      const newBodyHeight = estimateBodyHeight();
      setBodyHeight(newBodyHeight);

      dispatch(NodeActions.setNodeHandles({ nodeId: id, handles: handleIdsObject }));
    },
    [dispatch, estimateBodyHeight, id, sourceHandleIds, targetHandleIds, updateNodeInternals]
  );

  const onNodeNameChange = React.useCallback(
    (newName) => {
      // Data Update
      const dataUpdates = { name: newName, symbol: data.symbol, varType: data.vaType };

      // Dispatch Node Update Action
      dispatch(NodeActions.updateNodeInfo({ graphId, nodeUid: id, nodeInfo: dataUpdates }));
    },
    [dispatch, graphId, id, data]
  );

  //
  const nodeName = data?.name || "";

  //
  return (
    <div className="">
      <div className="node-header" title={`"${nodeName} (Control Volume)"`}>
        <button className="btn btn-add-handle left" title="Add Input" onClick={() => addHandle(true)}>
          +
        </button>
        <InlineEdit className="node-title px-3" value={nodeName} setValue={onNodeNameChange} />
        <button className="btn btn-add-handle right" title="Add Output" onClick={() => addHandle(false)}>
          +
        </button>
      </div>
      <div className="node-body" style={{ minHeight: bodyHeight }}>
        {parameters && parameters.length > 0 && (
          <div className="sec-info">
            <p className="sec-title">Parameters</p>
            <ul className="list-unstyled mb-1">
              {parameters.map((p, idx) => {
                return (
                  <li key={`node-${id}-param-${idx}`}>
                    {p.name} ( <small>{p.symbol}</small> )
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        <div className="sec-info d-none">
          <p className="sec-title">Equations</p>
        </div>
      </div>

      {/** Source Handles */}
      <HandlesCont
        nodeId={id}
        type={"source"}
        handles={sourceHandleIds}
        topOffset={PX_TOP_OFFSET}
        spacing={PX_SPACING}
      />

      {/** Target Handles */}
      <HandlesCont
        nodeId={id}
        type={"target"}
        handles={targetHandleIds}
        topOffset={PX_TOP_OFFSET}
        spacing={PX_SPACING}
      />
    </div>
  );
}

export default memo(ControlVolumeNode);
