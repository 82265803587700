//
// URLs of Web Service
//

// Auth URLs
export const AuthUrls = {
  login: "/api/v1/auth/token",
  resetPassword: "/api/v1/auth/passwd_reset",
};

// User URLs
export const UserUrls = {
  //
  createUser: "/api/v1/user",

  //
  updateUser: "/api/v1/user/:userId",
  enableUser: "/api/v1/user/:userId/enable",
  disableUser: "/api/v1/user/:userId/disable",
  updateUserRole: "/api/v1/user/:userId/privileges",
  updateUserPassword: "/api/v1/auth/password",

  //
  getUser: "/api/v1/user/:userId",
  searchUsers: "/api/v1/user/search",
};

// Edge URLs
export const EdgeUrls = {
  //
  createEdge: "/api/v1/graph/:graphId/edge",

  //
  updateEdgeInfoByUid: "/api/v1/graph/:graphId/edge/:edgeUid",
  updateEdgeVarInfoByUid_Old: "/api/v1/graph/:graphId/edge/:edgeUid/var_old", // Deprecated
  updateEdgeVarInfoByUid: "/api/v1/graph/:graphId/edge/:edgeUid/var",
  updateEdgeStyleByUid: "/api/v1/graph/:graphId/edge/:edgeUid/style",

  //
  deleteEdgeByUid: "/api/v1/graph/:graphId/edge/:edgeUid",
};

// Equation URLs
export const EquationUrls = {
  //
  createEquation: "/api/v1/graph/:graphId/equation",

  //
  updateEquation: "/api/v1/graph/:graphId/equation/:equationId",

  //
  deleteEquation: "/api/v1/graph/:graphId/equation/:equationId",
};

// Graph URLs
export const GraphUrls = {
  //
  getDataById: "/api/v1/graph/:graphId",

  //
  refreshGraphSetup: "/api/v1/graph/:graphId/refresh",
};

// Node URLs
export const NodeUrls = {
  //
  createNode: "/api/v1/graph/:graphId/node",

  //
  updateNodeInfoByUid: "/api/v1/graph/:graphId/node/:nodeUid",
  updateNodeStyleByUid: "/api/v1/graph/:graphId/node/:nodeUid/style",
  updateNodePositionByUid: "/api/v1/graph/:graphId/node/:nodeUid/position",
  updateNodeEqnGenStatusByUid: "/api/v1/graph/:graphId/node/:nodeUid/eqns",

  //
  deleteNodeByUid: "/api/v1/graph/:graphId/node/:nodeUid",
};

// Parameter URLs
export const ParameterUrls = {
  //
  createParameter: "/api/v1/graph/:graphId/parameter",

  //
  updateParameter: "/api/v1/graph/:graphId/parameter/:parameterId",

  //
  deleteParameter: "/api/v1/graph/:graphId/parameter/:parameterId",
};

//
// Project
// ----------------------------------------------------------------------------

// Project URLs
export const ProjectUrls = {
  //
  createProject: "/api/v1/project",

  //
  updateProjectInfo: "/api/v1/project/:projectId",

  //
  getProject: "/api/v1/project/:projectId",
  searchProjects: "/api/v1/project/search",
};

// Project :: OptSetup URLs
export const OptSetupUrls = {
  //
  createOptSetup: "/api/v1/project/:projectId/opt",

  //
  updateOptSetup: "/api/v1/project/:projectId/opt/:optSetupId",

  //
  getOptSetup: "/api/v1/project/:projectId/opt/:optSetupId",
  searchOptSetups: "/api/v1/project/:projectId/opt/search",
};

// Project :: OptRun URLs
export const OptRunUrls = {
  //
  createOptRun: "/api/v1/project/:projectId/opt/:optSetupId/run",

  //
  deleteOptRun: "/api/v1/project/:projectId/opt/:optSetupId/run/:optRunId",
  deleteOptRunsBySetup: "/api/v1/project/:projectId/opt/:optSetupId/run",

  //
  getOptRun: "/api/v1/project/:projectId/opt/:optSetupId/run/:optRunId",
  searchOptRuns: "/api/v1/project/:projectId/opt/:optSetupId/run/search",

  //
  getOptRunProblem: "/api/v1/project/:projectId/opt/:optSetupId/run/problem",
};

// Project :: OptRun as Job URLs
export const OptRunJobUrls = {
  //
  createOptRunJob: "/api/v1/project/:projectId/opt/:optSetupId/run/job",

  //
  getOptSetupRunJobs: "/api/v1/project/:projectId/opt/:optSetupId/run/jobs",
};

// Project :: File
export const ProjectFileUrls = {
  //
  createFile: "/api/v1/project/:projectId/file",

  //
  updateFileInfo: "/api/v1/project/:projectId/file/:fileId",

  //
  getFileInfo: "/api/v1/project/:projectId/file/:fileId",
  searchFiles: "/api/v1/project/:projectId/file/search",
};
