import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applyNodeChanges } from "reactflow";

// Actions
import { NodeActions } from "../redux-slice/graph/nodeSlice";
import { ElementActions } from "../redux-slice/graph/elementSlice";

// Config
import GraphConfig from "../reactflow/GraphConfig";

/// Utils
import MapUtils from "../utils/mapUtils";

// Constant
const { NODE } = GraphConfig.ReactFlowElements;

export function useOnNodeClick() {
  // Dispatch
  const dispatch = useDispatch();

  return useCallback((event, node) => {
    dispatch(ElementActions.selectOnlyOneElement({ elementId: node.id, elementType: NODE }));
  }, []);
}

export function useOnNodesChanges() {
  // Dispatch
  const dispatch = useDispatch();
  const nodesMap = useSelector((state) => state.node.nodeMap);
  const nodes = Object.values(nodesMap);

  return useCallback(
    (changes) => {
      // NOTE: We are deepcloning node because Reactflow internally expects the node to be updated
      // to be a new object altogether (As ReactFlow follows immutability)..
      const nodesToUpdate = [];

      // construct changes Map;
      const changesMap = MapUtils.convertArrayToMap(changes, "id");

      for (const node of nodes) {
        const { id: nodeId } = node;
        const chageForNode = changesMap[nodeId];
        chageForNode ? nodesToUpdate.push(structuredClone(node)) : nodesToUpdate.push(node);
      }

      const updatedNodes = applyNodeChanges(changes, nodesToUpdate);
      dispatch(NodeActions.setNodes({ nodes: updatedNodes }));
    },
    [nodes]
  );
}
