import { createSlice } from "@reduxjs/toolkit";

// Intital state
const initialState = {
  equationMap: {},

  //Add Equations state
  addEquationLoading: false,
  addEquationErr: "",

  // Update Equations state
  updateEquationLoading: false,
  updateEquationErr: "",

  // Delete Equations state
  equationIdToBeDeleted: "",
  deleteEquationLoading: false,
  deleteEquationErr: "",
};

// Equation Slice
const equationSlice = createSlice({
  // Name
  name: "Equation",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    setEquations: (state, action) => {
      const { equations = [] } = action.payload;
      state.equationMap = equations.reduce((acc, equation) => {
        const { id } = equation;
        acc[id] = equation;
        return acc;
      }, {});
    },

    // Add Equation
    addEquation: (state) => {
      state.addEquationLoading = true;
      state.addEquationErr = {};
    },

    addEquationSuccess: (state, action) => {
      const { equationObj } = action.payload;
      const { id, nodeId } = equationObj;

      // We just need to add only graph equations as node equations are added in NodeSlice..
      if (!nodeId) {
        state.equationMap[id] = equationObj;
      }
      state.addEquationLoading = false;
    },

    addEquationFailure: (state, action) => {
      state.addEquationLoading = false;
      state.addEquationErr = action.payload.error;
    },

    // Update Equation
    updateEquation: (state) => {
      state.updateEquationLoading = true;
      state.updateEquationErr = {};
    },

    updateEquationSuccess: (state, action) => {
      const { equationObj } = action.payload;
      const { id, nodeId } = equationObj;

      // We just need to update only graph equations as node equations are updated in NodeSlice..
      if (!nodeId) {
        state.equationMap[id] = equationObj;
      }
      state.updateEquationLoading = false;
    },

    updateEquationFailure: (state, action) => {
      state.updateEquationLoading = false;
      state.updateEquationErr = action.payload.error;
    },

    // Delete Equation
    deleteEquation: (state, action) => {
      const { equationObj } = action.payload;
      state.deleteEquationLoading = true;
      state.equationIdToBeDeleted = equationObj.id;
      state.deleteEquationErr = "";
    },

    deleteEquationSuccess: (state, action) => {
      const { equationId, nodeId = "" } = action.payload;

      // We just need to delete only graph equations as node equations are deleted in NodeSlice..
      if (!nodeId) {
        delete state.equationMap[equationId];
      }

      state.deleteEquationLoading = false;
      state.equationIdToBeDeleted = "";
    },

    deleteEquationFailure: (state, action) => {
      state.deleteEquationLoading = false;
      state.deleteEquationErr = action.payload.error;
      state.equationIdToBeDeleted = "";
    },
  },
});

// Reducer
export const EquationReducer = equationSlice.reducer;

// Actions
export const EquationActions = equationSlice.actions;
