import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { ParameterActions } from "../../redux-slice/graph/parameterSlice";
import { NodeActions } from "../../redux-slice/graph/nodeSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";

// Service
import ParameterService from "../../services/graph/parameterService";

// Create Parameter
export function* createParameter(action) {
  const { parameterObj = {} } = action.payload;

  try {
    const { graphId = "", nodeUid: nodeId } = parameterObj;
    const newParameter = yield ParameterService.createParameter(graphId, parameterObj);

    // Add Parameter details to the node..
    if (nodeId) {
      yield put(NodeActions.upsertParameter({ parameterObj: newParameter }));
    }

    yield put(ParameterActions.createParameterSuccess({ parameterObj: newParameter }));
  } catch (error) {
    yield put(ParameterActions.createParameterFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Update Parameter
export function* updateParameter(action) {
  const { parameterId, parameterObj } = action.payload;

  try {
    const { graphId, nodeUid: nodeId } = parameterObj;
    yield ParameterService.updateParameter(graphId, parameterId, parameterObj);

    // Update the parameter details in the Node..
    if (nodeId) {
      yield put(NodeActions.upsertParameter({ parameterObj: parameterObj }));
    }

    yield put(ParameterActions.updateParameterSuccess({ parameterObj: parameterObj }));
  } catch (error) {
    yield put(ParameterActions.updateParameterFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

export function* deleteParameter(action) {
  const { parameterObj, graphId } = action.payload;

  try {
    const { id: parameterId, nodeUid: nodeId } = parameterObj;
    yield ParameterService.deleteParameter(graphId, parameterId);

    // Delete the parameter details in the Node..
    if (nodeId) {
      yield put(NodeActions.deleteParameter({ nodeId, parameterId }));
    }

    yield put(ParameterActions.deleteParameterSuccess({ parameterId }));
  } catch (error) {
    yield put(ParameterActions.deleteParameterFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

//
// Export

export default function* root() {
  yield all([
    //
    takeLatest(ParameterActions.createParameter.type, createParameter),

    //
    takeLatest(ParameterActions.updateParameter.type, updateParameter),

    //
    takeLatest(ParameterActions.deleteParameter.type, deleteParameter),
  ]);
}
