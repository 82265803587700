import { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NodeActions } from "../../redux-slice/graph/nodeSlice.js";
import HandlesCont from "../connection/HandlesCont.jsx";
import GraphUtil from "../GraphUtil";

//
// Constants
const PX_TOP_OFFSET = 35;
const PX_SPACING = 30;

//
// SourceNode
// ----------------------------------------------------------------------------

function SourceNode({ id, type, selected, data = {} }) {
  const dispatch = useDispatch();
  const node = useSelector((state) => state.node.nodeMap[id]) || {};
  const { dimensions } = node;

  // Handles
  const handleIds = data.handles?.source || [];

  // Parameters
  const parametersMap = data.parametersMap || {};
  const parameters = Object.values(parametersMap);

  // Component State
  const [handlesCount, setHandlesCount] = useState(handleIds.length);
  const [bodyHeight, setBodyHeight] = useState(estimateBodyHeight());

  //
  // Functions

  function estimateBodyHeight() {
    // Update Body Height
    const bodyHeightUnits = handlesCount;
    const newBodyHeight = (bodyHeightUnits + 1) * PX_SPACING;

    return newBodyHeight;
  }

  function addHandle(isInput) {
    // Handles Array
    const handleCnt = handleIds.length;
    const lastHandleId = handleCnt === 0 ? "" : handleIds[handleCnt - 1];
    const nextHandleId = GraphUtil.nextHandleId(isInput, lastHandleId);

    const updatedHandleIds = [...handleIds, nextHandleId];
    const handleIdsObject = { source: updatedHandleIds };

    // Update Count to trigger re-render
    setHandlesCount(handlesCount + 1);

    // Body Height
    const newBodyHeight = estimateBodyHeight();
    setBodyHeight(newBodyHeight);

    const updatedDimensions = { ...dimensions, height: newBodyHeight };
    dispatch(NodeActions.setNodeHandles({ nodeId: id, handles: handleIdsObject, dimensions: updatedDimensions }));
  }

  //
  const nodeName = data?.name || "";

  //
  return (
    <>
      <div className="node-body" title={`"${nodeName} (Source)"`} style={{ minHeight: bodyHeight }}>
        <div>
          <small className="mx-2 px-1">Source</small>
          <div className="node-title">
            <small>{nodeName}</small>
          </div>
          <button className="btn btn-add-handle right" title="Add Output" onClick={() => addHandle(false)}>
            +
          </button>
        </div>

        {parameters && parameters.length > 0 && (
          <div className="sec-info">
            <p className="sec-title">Pameters</p>
            <ul className="list-unstyled mb-1">
              {parameters.map((p, idx) => {
                return (
                  <li key={`node-${id}-param-${idx}`}>
                    {p.name} ( <small>{p.symbol}</small> )
                  </li>
                );
              })}
            </ul>
          </div>
        )}
        <div className="sec-info d-none">
          <p className="sec-title">Equations</p>
        </div>
      </div>

      {/** Source Handles */}
      <HandlesCont nodeId={id} type={"source"} handles={handleIds} topOffset={PX_TOP_OFFSET} spacing={PX_SPACING} />
    </>
  );
}

export default memo(SourceNode);
