/**
 * Function which converts array to Map
 * arrayObj ==> [{"id":1, "value":"macha1"},{"id":2, "value":"macha2"},{"id":3, "value":"macha3"}]
 * objectKey ==> "id"
 * 
 * convertedMap ==> {
  '1': { id: 1, value: 'macha1' },
  '2': { id: 2, value: 'macha2' },
  '3': { id: 3, value: 'macha3' }
}
 * @param {*} arrayObj 
 * @param {*} objectKey 
 * @returns 
 */
function convertArrayToMap(arrayObj = [], objectKey) {
  return arrayObj.reduce((acc, object) => {
    const objectKeyValue = object[objectKey];
    acc[objectKeyValue] = object;
    return acc;
  }, {});
}

const MapUtils = { convertArrayToMap };
export default MapUtils;
