import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { ParameterActions } from "../../../../redux-slice/graph/parameterSlice";

// Constants
import { ICON_SIZE } from "../../../../constants/GeneralConstants";

// Components
import CardCollapsible from "../../../../components/card-collapsible/CardCollapsible";
import GraphParameterModal from "../modal/GraphParameterModal";
import Icon from "../../../../components/icon/Icon";
import Button from "../../../../components/button/Button";
import Loader from "../../../../components/loader/Loader";

// Page Components
function EditAndDeleteIcon({ parameter, isGenerated, openParameterModal, deleteNodeParameter }) {
  const deleteParameterLoading = useSelector((state) => state.parameter.deleteParameterLoading);
  const parameterIdToBeDeleted = useSelector((state) => state.parameter.parameterIdToBeDeleted);

  if (isGenerated) {
    return null;
  }

  if (deleteParameterLoading && parameterIdToBeDeleted === parameter.id) {
    return <Loader />;
  }

  return (
    <span>
      <Icon
        className="me-2"
        onClick={() => openParameterModal(parameter)}
        iconName="pencil"
        size={ICON_SIZE.SM}
        color="gold"
        strokeWidth={3}
        title="Click to edit"
      />

      <Icon
        onClick={() => deleteNodeParameter(parameter)}
        iconName="circleX"
        size={ICON_SIZE.SM}
        color="red"
        strokeWidth={3}
        title="Click to delete"
      />
    </span>
  );
}

/**
 * Graph Parameters Card
 */

export default function GraphParametersCard({
  collapsed,
  graphId = "",
  nodeUid = "",
  parameters = [],
  parameterVarPrefix,
}) {
  // dispatch
  const dispatch = useDispatch();

  // State
  const [selectedParameter, setSelectedParameter] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openParameterModal(p = {}) {
    setSelectedParameter(p);
    setIsModalOpen(true);
  }

  function closeEditParameterModal() {
    setIsModalOpen(false);
  }

  // Parameter Delete
  function deleteNodeParameter(parameterObj) {
    // Dispatch Action
    dispatch(ParameterActions.deleteParameter({ parameterObj, graphId }));

    // update state
    setSelectedParameter({});
  }

  // Form onSubmit
  function onFormSubmit({ varType, name, symbol }) {
    // Parameter Object
    const parameterObj = {
      ...selectedParameter,
      graphId,
      nodeUid,
      varType,
      name,
      symbol,
    };

    const parameterId = selectedParameter?.id;

    // Dispatch Action
    const isUpdate = parameterId !== undefined;

    if (isUpdate) {
      dispatch(ParameterActions.updateParameter({ parameterId, parameterObj, setIsModalOpen }));
    } else {
      dispatch(ParameterActions.createParameter({ parameterObj, setIsModalOpen }));
    }
  }

  //
  return (
    <>
      <CardCollapsible header={"Parameters"} collapsed={collapsed} className="pb-1" id="card-sel-node-params">
        <>
          <ul className="list-unstyled list-graph-elems">
            {parameters.map((p) => {
              const { id = "", name = "", symbol = "", isGenerated = false } = p || {};

              //
              return (
                <li key={`param-${id}`}>
                  <span>
                    {name} ( {symbol} )
                  </span>

                  <EditAndDeleteIcon
                    parameter={p}
                    isGenerated={isGenerated}
                    openParameterModal={() => openParameterModal(p)}
                    deleteNodeParameter={() => deleteNodeParameter(p)}
                  />
                </li>
              );
            })}
          </ul>
          <div>
            <Button className="btn btn-xs btn-outline-secondary" onClick={() => openParameterModal()}>
              Add Parameter
            </Button>
          </div>
        </>
      </CardCollapsible>

      {/** Parameter Modal */}
      <GraphParameterModal
        isModalOpen={isModalOpen}
        closeModal={closeEditParameterModal}
        graphId={graphId}
        nodeUid={nodeUid}
        parameter={selectedParameter}
        varPrefix={parameterVarPrefix}
        onSave={onFormSubmit}
      />
    </>
  );
}
