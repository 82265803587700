import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// App

// Constants
import { ICON_SIZE } from "../../../constants/GeneralConstants";

// Components
import GraphConfig from "../../../reactflow/GraphConfig";
import Icon from "../../../components/icon/Icon";

// Page Constants
const EdgeTypeNameMap = GraphConfig.EdgeTypeNameMap;

//
// Component
// ----------------------------------------------------------------------------

export default function SectionSelectionInfo({ selectedNodeKeys = [], selectedEdgeKeys = [] }) {
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [selectedEdges, setSelectedEdges] = useState([]);

  // Counts
  const selectedNodesCount = selectedNodeKeys.length;
  const selectedEdgesCount = selectedEdgeKeys.length;

  const nodeMap = useSelector((state) => state.node.nodeMap);
  const edgeMap = useSelector((state) => state.edge.edgeMap);

  useEffect(() => {
    const selectedEdgesArray = selectedEdgeKeys.map((edgeId) => edgeMap[edgeId]);
    setSelectedEdges(selectedEdgesArray);
  }, [edgeMap, selectedEdgeKeys]);

  useEffect(() => {
    const selectedNodesArray = selectedNodeKeys.map((nodeId) => nodeMap[nodeId]);
    setSelectedNodes(selectedNodesArray);
  }, [nodeMap, selectedNodeKeys]);

  //
  return (
    <>
      <div className="sec-info p-3">
        <h6 className="px-1 mb-2">Selection</h6>
      </div>

      {selectedNodesCount > 0 && (
        <div className="sec-info p-3 px-4">
          <h6 className="mb-3">Nodes</h6>

          <ul className="list-unstyled list-graph-elems">
            {selectedNodes.map((n) => {
              const { id: nId, data: nData = {} } = n;

              return (
                <li className="" key={`sel-node-list-${nId}`}>
                  <span className="">
                    <Icon iconName="chevronRight" size={ICON_SIZE.MD} />
                    {nData.name}
                  </span>
                  <small className="text-secondary">{nId}</small>
                </li>
              );
            })}
          </ul>
        </div>
      )}

      {selectedEdgesCount > 0 && (
        <div className="sec-info p-3 px-4">
          <h6 className="mb-3">Edges</h6>

          <ul className="list-unstyled list-graph-elems">
            {selectedEdges.map((e) => {
              const eId = e.id;
              const eData = e.data || {};

              return (
                <li className="" key={`sel-edge-list-${eId}`}>
                  <span className="">
                    <Icon iconName="chevronRight" size={ICON_SIZE.MD} />
                    {eData.name}
                  </span>
                  <small className="text-secondary">{eId}</small>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
}
