import { createSlice } from "@reduxjs/toolkit";

// Transformer
import ParameterTransformer from "./parameterTransformer";

// Intital state
const initialState = {
  parameterMap: {},

  // Create Paramter
  createParameterLoading: false,
  createParameterError: "",

  // Update Parameters
  updateParameterLoading: false,
  updateParameterError: "",

  // Delete Parameters
  parameterIdToBeDeleted: "",
  deleteParameterLoading: false,
  deleteParameterError: "",
};

// Parameter Slice
const parameterSlice = createSlice({
  // Name
  name: "Parameter",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    setParameters: (state, action) => {
      const { parameters = [] } = action.payload;
      state.parameterMap = parameters.reduce((acc, parameter) => {
        const { id } = parameter;
        acc[id] = parameter;
        return acc;
      }, {});
    },

    // Add Paramter
    createParameter: (state) => {
      state.createParameterLoading = true;
      state.createParameterError = "";
    },

    createParameterSuccess: (state, action) => {
      const { parameterObj } = action.payload;
      const transformedParameterDetails = ParameterTransformer.transformObj(parameterObj);
      const { id, nodeId } = transformedParameterDetails;

      // We just need to add only graphParam as nodeParamters are added in NodeSlice..
      if (!nodeId) {
        state.parameterMap[id] = transformedParameterDetails;
      }

      state.createParameterLoading = false;
    },

    createParameterFailure: (state, action) => {
      state.createParameterLoading = false;
      state.createParameterError = action.payload.error;
    },

    // Update Paramter
    updateParameter: (state) => {
      state.updateParameterLoading = true;
      state.updateParameterError = "";
    },

    updateParameterSuccess: (state, action) => {
      const { parameterObj } = action.payload;
      const transformedParameterDetails = ParameterTransformer.transformObj(parameterObj);
      const { id, nodeId } = transformedParameterDetails;

      // We just need to update only graphParam as nodeParamters are updated in NodeSlice..
      if (!nodeId) {
        state.parameterMap[id] = transformedParameterDetails;
      }

      state.updateParameterLoading = false;
    },

    updateParameterFailure: (state, action) => {
      state.updateParameterLoading = false;
      state.updateParameterError = action.payload.error;
    },

    // Delete Paramter
    deleteParameter: (state, action) => {
      const { parameterObj } = action.payload;
      const { id: parameterId } = parameterObj;

      state.parameterIdToBeDeleted = parameterId;
      state.deleteParameterLoading = true;
      state.deleteParameterError = "";
    },

    deleteParameterSuccess: (state, action) => {
      const { parameterId, nodeId = "" } = action.payload;

      // We just need to delete only graphParam as nodeParamters are deleted in NodeSlice..
      if (!nodeId) {
        delete state.parameterMap[parameterId];
      }

      state.deleteParameterLoading = false;
      state.parameterIdToBeDeleted = "";
    },

    deleteParameterFailure: (state, action) => {
      state.deleteParameterLoading = false;
      state.deleteParameterError = action.payload.error;
      state.parameterIdToBeDeleted = "";
    },
  },
});

// Reducer
export const ParameterReducer = parameterSlice.reducer;

// Actions
export const ParameterActions = parameterSlice.actions;
