import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { GraphActions } from "../../redux-slice/graph/graphSlice";
import { setErrorInfo } from "../../app/error/ErrorActions";
import { NodeActions } from "../../redux-slice/graph/nodeSlice";
import { EdgeActions } from "../../redux-slice/graph/edgeSlice";
import { ParameterActions } from "../../redux-slice/graph/parameterSlice";
import { EquationActions } from "../../redux-slice/graph/equationSlice";

// Service
import GraphService from "../../services/graph/graphService";

// Utils
import GraphUtil from "../../reactflow/GraphUtil";

// Update Graph Entity data value
export function* refreshGraph(action) {
  const { graphId } = action.payload;

  try {
    yield GraphService.refreshGraph(graphId);

    // Re-fetch Graph data
    const graphData = yield GraphService.getGraphDataById(graphId);
    yield put(GraphActions.getGraphDataSuccess({ graphData }));

    //
    yield put(GraphActions.refreshGraphSuccess());
  } catch (error) {
    yield put(GraphActions.refreshGraphFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Validate Graph
function* validateGraph(action) {
  const { nodes = [], edges = [], parametersMap = {} } = action.payload;

  // Errors & Warnings
  const errors = {};
  const warnings = {};

  try {
    // Graph Parameters
    const graphParams = Object.values(parametersMap);
    const graphParamVars = graphParams.map((p) => p.symbol);

    // Go over each node, filter edgeVars and ParamVars and validate them against the equations
    for (const node of nodes) {
      const { id: nodeUid, data } = node;

      // Node Variables
      const nodeEdgeVars = edges //
        .filter((e) => e.source === nodeUid || e.target === nodeUid) //
        .map(({ data = {} }) => data.symbol);

      // Node Parameters
      const { parametersMap, equationsMap } = data;
      const nodeParamVars = Object.values(parametersMap).map((p) => p.symbol);

      // All Node Variables (Variable space for the node)
      const allNodeVars = [...graphParamVars, ...nodeEdgeVars, ...nodeParamVars];

      // Node Equations
      const nodeEquations = equationsMap[nodeUid] || [];

      // Validate Node Variables against Equations
      for (const eqn of nodeEquations) {
        const { lhs } = eqn;

        // Equation Variables
        const eqnVars = lhs.match(/[a-zA-Z_][a-zA-Z0-9_]*/g) || [];
        if (eqnVars.length === 0) {
          continue;
        }

        // Check if all equation variables are present Node Variable Space
        for (const eqnVar of eqnVars) {
          if (allNodeVars.indexOf(eqnVar) < 0) {
            // Equation Variable is Not found in Node Variables
            errors[nodeUid] = errors[nodeUid] || [];
            errors[nodeUid].push(`Unknown Var: ${eqnVar} in eqn ${lhs}`);
          }

          // Remove the variable from the Node Variales / Parameter Space
          nodeEdgeVars.splice(nodeEdgeVars.indexOf(eqnVar), 1);
          nodeParamVars.splice(nodeParamVars.indexOf(eqnVar), 1);
        }
      }

      // Add Warning for unused variables
      warnings[nodeUid] = warnings[nodeUid] || [];
      nodeEdgeVars.forEach((v) => {
        warnings[nodeUid].push(`Unused Edge : ${v}`);
      });
      nodeParamVars.forEach((p) => {
        warnings[nodeUid].push(`Unused Parameter: ${p}`);
      });
    }

    // set timeout
    yield new Promise((resolve) => setTimeout(resolve, 500));

    // Update State
    yield put(GraphActions.validateGraphCompleted({ errors, warnings, validationSuccess: true }));

    //
  } catch (error) {
    yield put(GraphActions.validateGraphCompleted({ validationSuccess: false }));
  }
}

// Get GraphData By Id
function* getGraphDataById(action) {
  const { graphId } = action.payload;
  try {
    const graphData = yield GraphService.getGraphDataById(graphId);
    const { nodes, edges } = GraphUtil.toReactFlowFormat(graphId, graphData);
    const { parametersMap, equationsMap } = graphData;

    // Set Nodes and Edges
    yield put(NodeActions.setNodes({ nodes }));
    yield put(EdgeActions.setEdges({ edges }));

    // Set Graph Parameters
    yield put(ParameterActions.setParameters({ parameters: parametersMap[graphId] }));

    // Set Graph Equations
    yield put(EquationActions.setEquations({ equations: equationsMap[graphId] }));

    // Dispatching Action
    yield put(GraphActions.getGraphDataSuccess({ graphData, graphId }));
  } catch (error) {
    yield put(GraphActions.getGraphDataFailure({ error }));

    // Show error toaster or redirect to error page
    yield put(setErrorInfo({ errorInfo: error, showToaster: true }));
  }
}

// Export
export default function* root() {
  yield all([
    //
    takeLatest(GraphActions.refreshGraph.type, refreshGraph),
    takeLatest(GraphActions.validateGraph.type, validateGraph),

    //
    takeLatest(GraphActions.getGraphData.type, getGraphDataById),
  ]);
}
