import React, { useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import { useDispatch } from "react-redux";

// Constants
import { ICON_SIZE } from "../../../constants/GeneralConstants";

// Components
import CardCollapsible from "../../../components/card-collapsible/CardCollapsible";
import Icon from "../../../components/icon/Icon";

// Components :: ReactFlow
import GraphConfig from "../../../reactflow/GraphConfig";

// Custom Hook
import { useUpdateQueryParams } from "../../../hooks/useUpdateQueryParams";

// Constant
import { QueryParamsKeys } from "../../../constants/WebConstants";
import Loader from "../../../components/loader/Loader";

// Actions
import { NodeActions } from "../../../redux-slice/graph/nodeSlice";
import { ElementActions } from "../../../redux-slice/graph/elementSlice";

//
// Constants
const NodeTypeNameMap = GraphConfig.NodeTypeNameMap;

//
// Component
// ----------------------------------------------------------------------------

function SearchNodeSection({ searchNodeName, setSearchNodeName }) {
  return (
    <input
      className="w-100 form-control my-3"
      placeholder="Enter Node to Search..."
      value={searchNodeName}
      onChange={(e) => setSearchNodeName(e.target.value)}
    ></input>
  );
}

export default function CanvasSidebarLeft({
  graphId,
  nodes = [],
  warnings = {},
  errors = {},
  validationLoading = false,
}) {
  const dispatch = useDispatch();

  const { setCenter, getNode, fitView } = useReactFlow();
  const [searchNodeName, setSearchNodeName] = useState("");
  const [filteredNodes, setFilteredNodes] = useState(nodes);

  const noNodesMessage = searchNodeName ? "No Nodes found with this name..." : "No Nodes";

  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();
  const showEdges = searchParams.get(QueryParamsKeys.showEdge) === "true" ? true : false;

  //
  // Graph State :: Selection
  const { selectedNodes, selectedNode, selectedNodeWarnings, selectedNodeErrors } = React.useMemo(() => {
    const selectedNodes = nodes.filter((n) => n.selected) || [];
    if (selectedNodes.length !== 1) {
      return { selectedNodes, selectedNode: undefined, selectedNodeWarnings: [], selectedNodeErrors: [] };
    }

    // Selected Node
    const selectedNode = selectedNodes[0];

    // Selected Node Warnings & Errors
    const selectedNodeUid = selectedNodes[0].id;
    const selectedNodeWarnings = warnings[selectedNodeUid] || [];
    const selectedNodeErrors = errors[selectedNodeUid] || [];

    return { selectedNodes, selectedNode, selectedNodeWarnings, selectedNodeErrors };
  }, [nodes, warnings, errors]);

  const toggleNodeSelectStatus = (node) => {
    const { id, selected } = node;
    const actionParams = { isSelected: false, nodeId: id };
    const { NODE } = GraphConfig.ReactFlowElements;

    if (selected) {
      dispatch(NodeActions.setNodeStatus(actionParams));
      dispatch(ElementActions.unSelectElement({ elementId: id, elementType: NODE }));
      fitView();
      return;
    }

    actionParams.isSelected = true;
    dispatch(NodeActions.setNodeStatus(actionParams));
    dispatch(ElementActions.selectElement({ elementId: id, elementType: NODE }));
    const selectedNode = getNode(id);
    const { position } = selectedNode;
    setCenter(position.x + 100, position.y + 50, { zoom: 2 });
  };

  const handleShowEdge = () => {
    const params = {
      showEdge: !showEdges,
    };

    updateQueryParams({ params });
  };

  useEffect(() => {
    setFilteredNodes(nodes);
  }, [nodes]);

  useEffect(() => {
    const filteredNodes = nodes.filter(({ data }) => data.name.toLowerCase().includes(searchNodeName.toLowerCase()));
    setFilteredNodes(filteredNodes);
  }, [searchNodeName]);

  //
  return (
    <div className="sidebar sidebar-left">
      <div className="position-relative">
        <div className="sec-info">
          {/** Nodes List */}
          <CardCollapsible header={"Modules (Nodes)"} collapsed={false} id="card-graph-nodes">
            {/** Search Nodes by Name Section */}
            <SearchNodeSection searchNodeName={searchNodeName} setSearchNodeName={setSearchNodeName} />

            <ul className="list-unstyled list-item-selectable list-graph-elems">
              {filteredNodes.length === 0 && <p>{noNodesMessage}</p>}

              {filteredNodes.map((n) => {
                const { id: nId, type: nType, data: nData = {}, selected: isSelected } = n;
                return (
                  <li
                    className={`${isSelected ? "active" : ""}`}
                    key={`node-list-${nId}`}
                    onClick={() => toggleNodeSelectStatus(n)}
                    title="Click to Select Node"
                  >
                    <span>
                      <Icon iconName="square" fill={isSelected ? "black" : "transparent"} size={13} className="me-1" />
                      {nData.name}
                    </span>
                    <small className="text-secondary">{NodeTypeNameMap[nType]}</small>
                  </li>
                );
              })}
            </ul>
          </CardCollapsible>
        </div>

        <div className={`sec-info ${selectedNode ? "" : "d-none"}`}>
          {/** Validation */}
          <CardCollapsible header={"Node Problems"} collapsed={false} className="" id="card-validation-res">
            {selectedNode && ( //
              <p className="fw-bold mb-2">{selectedNode.data?.name}</p>
            )}

            {validationLoading && ( //
              <Loader containerClassName="text-center py-2 mb-2" />
            )}

            {!validationLoading && ( //
              <>
                {/** Warnings */}
                <ul className="list-unstyled">
                  {selectedNodeWarnings.map((w, idx) => {
                    return (
                      <li className="text-warning" key={`node-warn-list-${idx}`}>
                        <Icon iconName="triangleAlert" size={ICON_SIZE.MD} className="me-2" />
                        {w}
                      </li>
                    );
                  })}
                </ul>

                {/** Errors */}
                <ul className="list-unstyled">
                  {selectedNodeErrors.map((e, idx) => {
                    return (
                      <li className="text-danger" key={`node-err-list-${idx}`}>
                        <Icon iconName="octagonAlert" size={ICON_SIZE.MD} className="me-2" /> {e}
                      </li>
                    );
                  })}
                </ul>
              </>
            )}

            <small className="fst-italic">** Re validate for latest problems</small>
          </CardCollapsible>
        </div>

        <div className="sec-info d-none">
          {/* Settings */}
          <CardCollapsible header={"Settings"} collapsed={false} className="" id="card-node-settings">
            <div className="form-check form-switch">
              <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                Show Edges
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                onChange={handleShowEdge}
                checked={showEdges}
              />
            </div>
          </CardCollapsible>
        </div>
      </div>
    </div>
  );
}
