import { useCallback } from "react";
import { useDispatch } from "react-redux";

// Actions
import { ElementActions } from "../redux-slice/graph/elementSlice";

// Config
import GraphConfig from "../reactflow/GraphConfig";

const { PANEL, PANEL_ID } = GraphConfig.ReactFlowElements;

export function useOnPanelClick() {
  // Dispatch
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(
      ElementActions.selectOnlyOneElement({
        elementId: PANEL_ID,
        elementType: PANEL,
      })
    );
  }, []);
}
