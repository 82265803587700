import React from "react";
import { useSelector } from "react-redux";

// Components
import SectionEdgeInfo from "./SectionEdgeInfo";
import SectionNodeInfo from "./SectionNodeInfo";
import SectionSelectionInfo from "./SectionSelectionInfo";
import SectionGraphInfo from "./SectionGraphInfo";

// Config
import GraphConfig from "../../../reactflow/GraphConfig";

// Helper functions
function getSelectedInfo(selectionObj = {}, dataMap = {}) {
  const keys = Object.keys(selectionObj);
  const selectedData = keys.length === 1 ? dataMap[keys[0]] : {};
  const isOnlySelected = keys.length === 1 ? true : false;

  return { keys, selectedData, isOnlySelected };
}

export default function CanvasSidebarRight({
  graphId,
  nodesMap = {},
  edgesMap = {},
  graphParametersMap = {},
  graphEquationsMap = {},
}) {
  const { NODE, EDGE } = GraphConfig.ReactFlowElements;
  //
  // Graph State
  const nodes = Object.values(nodesMap);
  const edges = Object.values(edgesMap);

  const nodeSelectionObj = useSelector((state) => state.element.elementSelectMap[NODE]);
  const selectedNodeKeys = Object.keys(nodeSelectionObj);
  const selectedNode = selectedNodeKeys.length === 1 ? nodesMap[selectedNodeKeys[0]] : {};
  const onlyNodeSelected = selectedNodeKeys.length === 1 ? true : false;

  const edgeSelectionObj = useSelector((state) => state.element.elementSelectMap[EDGE]);
  const selectedEdgeKeys = Object.keys(edgeSelectionObj);
  const selectedEdge = selectedEdgeKeys.length === 1 ? edgesMap[selectedEdgeKeys[0]] : {};
  const onlyEdgeSelected = selectedEdgeKeys.length === 1 ? true : false;

  const totalSelectionCount = selectedNodeKeys.length + selectedEdgeKeys.length;

  //
  //
  // Graph State :: Selcted Node

  // Node :: Connected Edges
  const { selectedNodeInEdges, selectedNodeOutEdges } = React.useMemo(() => {
    // Sanity checks
    if (!selectedNode) {
      return {};
    }

    const selNodeId = selectedNode.id;

    // Connected Edges
    const selectedNodeInEdges = [];
    const selectedNodeOutEdges = [];
    edges?.forEach((e) => {
      // Input
      if (selNodeId === e.target) {
        selectedNodeInEdges.push(e);
      }

      // Outout
      if (selNodeId === e.source) {
        selectedNodeOutEdges.push(e);
      }
    });

    return { selectedNodeInEdges, selectedNodeOutEdges };
  }, [selectedNode, edges]);

  // Edge :: Connected Nodes
  const { selectedEdgeSourceNode, selectedEdgeTargetNode } = React.useMemo(() => {
    // Sanity checks
    if (!selectedEdge) {
      return {};
    }

    // Source
    const sourceNodeId = selectedEdge.source;
    const selectedEdgeSourceNode = sourceNodeId && nodesMap[sourceNodeId];

    // Target
    const targetNodeId = selectedEdge.target;
    const selectedEdgeTargetNode = targetNodeId && nodesMap[targetNodeId];

    return { selectedEdgeSourceNode, selectedEdgeTargetNode };
  }, [selectedEdge, nodesMap]);

  // Graph State :: Variables
  const graphVariables = React.useMemo(() => {
    // Edges
    // TODO why do we need to add edge Data
    const edgeVars = edges.map(({ data }) => data);

    // Node Params
    // TODO why do we need to add node params..
    const nodeParamsArray = nodes.reduce((acc, node) => {
      const { data } = node;
      const { parametersMap } = data;
      acc = [...acc, Object.values(parametersMap)];
      return acc;
    }, []);

    // Parameters
    const paramVars = Object.values(graphParametersMap);

    // Variables
    const graphVariables = [...edgeVars, ...nodeParamsArray, ...paramVars];
    return graphVariables;
  }, [edges, nodes, graphParametersMap]);

  //
  return (
    <>
      <div className="sidebar sidebar-right">
        <div className="position-relative">
          {/** */}

          {/** Graph Info */}
          {totalSelectionCount === 0 && ( //
            <SectionGraphInfo
              graphId={graphId}
              parameters={Object.values(graphParametersMap)}
              equations={Object.values(graphEquationsMap)}
              variables={graphVariables}
            />
          )}

          {/** Selected Node Info */}
          {onlyNodeSelected && (
            <SectionNodeInfo
              graphId={graphId}
              node={selectedNode}
              inputEdges={selectedNodeInEdges}
              outputEdges={selectedNodeOutEdges}
            />
          )}

          {/** Selected Edge Info */}
          {onlyEdgeSelected && (
            <SectionEdgeInfo
              graphId={graphId}
              edge={selectedEdge}
              sourceNode={selectedEdgeSourceNode}
              targetNode={selectedEdgeTargetNode}
            />
          )}

          {/** Selected Elements Info*/}
          {totalSelectionCount > 1 && (
            <SectionSelectionInfo
              graphId={graphId}
              selectedNodeKeys={selectedNodeKeys}
              selectedEdgeKeys={selectedEdgeKeys}
            />
          )}

          {/** */}
        </div>
      </div>
    </>
  );
}
