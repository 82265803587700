import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { applyEdgeChanges } from "reactflow";

// Actions
import { EdgeActions } from "../redux-slice/graph/edgeSlice";
import { ElementActions } from "../redux-slice/graph/elementSlice";

// Config
import GraphConfig from "../reactflow/GraphConfig";

// Utils
import MapUtils from "../utils/mapUtils";

// Constant
const { EDGE } = GraphConfig.ReactFlowElements;

export function useOnEdgeClick() {
  // Dispatch
  const dispatch = useDispatch();

  return useCallback((event, edge) => {
    dispatch(ElementActions.selectOnlyOneElement({ elementId: edge.id, elementType: EDGE }));
  }, []);
}

export function useOnEdgesChanges() {
  // Dispatch
  const dispatch = useDispatch();
  const edgeMap = useSelector((state) => state.edge.edgeMap);
  const edges = Object.values(edgeMap);

  return useCallback(
    (changes) => {
      // NOTE: We are deepcloning Edge because Reactflow internally expects the Edge to be updated
      // to be a new object altogether (As ReactFlow follows immutability)..
      const edgesToUpdate = [];

      // construct changes Map;
      const changesMap = MapUtils.convertArrayToMap(changes, "id");

      for (const edge of edges) {
        const { id: edgeId } = edge;
        const chageForEdge = changesMap[edgeId];
        chageForEdge ? edgesToUpdate.push(structuredClone(edge)) : edgesToUpdate.push(edge);
      }

      const updatedEdges = applyEdgeChanges(changes, edges);
      dispatch(EdgeActions.setEdges({ edges: updatedEdges }));
    },
    [edges]
  );
}
