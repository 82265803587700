/**
 * Graph Utilities
 */

// Utils
import TextUtil from "../app/utils/TextUtil";
import MapUtils from "../utils/mapUtils";

// Config

//
// Constants
// ----------------------------------------------------------------------------

//
const DELIMITER_ID = "-";
const DELIMITER_VAR_SYMBOL = "_";

const PREFIX_NODE = "n";
const PREFIX_HANDLE_INPUT = "t"; // Target
const PREFIX_HANDLE_OUTPUT = "s"; // Source

//
// Graph Utils
// ----------------------------------------------------------------------------

/**
 * Parse /graph response to ReactFlow format
 */
const toReactFlowFormat = (graphId, graphObj = {}) => {
  // Input Graph Object
  const { nodes = [], edges = [], parametersMap = {}, equationsMap = {} } = graphObj;

  // Node Handles (to be prepared from Edge ID)
  const sourceHandlesMap = {};
  const targetHandlesMap = {};

  // Process Edges and Prepare Reactflow Edges
  const rfEdges = [];
  edges.forEach((e) => {
    const { uid, type, varType, name, symbol, style } = e;
    const idSplits = uid.split(DELIMITER_ID);
    const source = idSplits[0];
    const sourceHandle = idSplits[1];
    const target = idSplits[2];
    const targetHandle = idSplits[3];

    //
    const typeStr = type?.toLowerCase();

    // Update NodeHandle data
    sourceHandlesMap[source] = [...(sourceHandlesMap[source] || []), sourceHandle];
    targetHandlesMap[target] = [...(targetHandlesMap[target] || []), targetHandle];

    // Prepare RF Edge
    const rfEdge = {
      id: uid,
      type: typeStr,
      source,
      sourceHandle,
      target,
      targetHandle,
      data: {
        type: typeStr,
        varType,
        name,
        symbol,
        style,
      },
    };

    // Add Edge to RF Edges
    rfEdges.push(rfEdge);
  });

  // Process Nodes and Prepare Reactflow Nodes
  const rfNodes = [];
  nodes.forEach((n) => {
    const { uid, type, name, symbol, varType, generateEqns, posX, posY, style } = n;

    //
    const typeStr = type?.toLowerCase();

    // Handles
    const srcHandles = sourceHandlesMap[uid] || [];
    const targHandles = targetHandlesMap[uid] || [];

    // Parameters
    const parameters = parametersMap[uid] || [];
    const nodeParametersMap = MapUtils.convertArrayToMap(parameters, "id");

    // Equation
    const equations = equationsMap[uid] || [];
    const nodeEquationsMap = MapUtils.convertArrayToMap(equations, "id");

    // Prepare RF Node
    const rfNode = {
      id: uid,
      type: typeStr,
      position: { x: posX, y: posY },
      data: {
        type: typeStr,
        name,
        symbol,
        varType,
        handles: {
          source: srcHandles.sort(),
          target: targHandles.sort(),
        },
        parametersMap: nodeParametersMap,
        equationsMap: nodeEquationsMap,
        style,
        generateEqns,
      },
    };

    // Add Edge to RF Edges
    rfNodes.push(rfNode);
  });

  // Result
  const rfGraph = { nodes: rfNodes, edges: rfEdges };
  return rfGraph;
};

//
// Node Utilities
// ----------------------------------------------------------------------------

const nextNodeId = (nodeType, lastNodeIdx = 0) => {
  // get Node idx
  const nextNodeIdx = lastNodeIdx + 1;
  const nextNodeId = PREFIX_NODE + "" + nextNodeIdx;

  return nextNodeId;
};

const prepareNewNode = (nodeType, lastNodeIdx = 0, exNodesCount = 0) => {
  // Generate Node ID
  const nodeId = nextNodeId(nodeType, lastNodeIdx);

  // Prepare Node Object
  const newNode = {
    id: nodeId,
    type: nodeType,
    position: {
      x: exNodesCount * 30,
      y: exNodesCount * 30,
    },
    data: {
      name: nodeId,
      symbol: nodeId,
      parametersMap: {},
      equationsMap: {},
      handles: {
        source: ["s10"],
        target: ["t10"],
      },
      generateEqns: true,
    },
  };

  return newNode;
};

//
// Node Handle Utilities
// ----------------------------------------------------------------------------

const toHandleUid = (nodeId = "", handleId = "") => {
  const handleUid = [nodeId, handleId].join(DELIMITER_ID);
  return handleUid;
};

const nextHandleId = (isInput = true, lastHandleId = "") => {
  // Prefix
  const prefix = isInput ? PREFIX_HANDLE_INPUT : PREFIX_HANDLE_OUTPUT;

  // get Handle idx
  const handleIdx = lastHandleId === "" ? 10 : Number(lastHandleId.substring(1));
  const nextHandleIdx = handleIdx + 1;
  const nextHandleId = prefix + "" + nextHandleIdx;

  return nextHandleId;
};

//
// Edge Utilities
// ----------------------------------------------------------------------------

const toEdgeId = (source, sourcePort, target, targetPort) => {
  const edgeId = [source, sourcePort, target, targetPort].join(DELIMITER_ID);
  return edgeId;
};

//
// Parameter Utilities
// ----------------------------------------------------------------------------

/**
 * @deprecated
 */
const toParamVarSymbol = (varPrefix, varType = "", name) => {
  // Var
  const varTypePrefix = TextUtil.toShortSymbol(varType, true);
  const nameShortSymbol = TextUtil.toShortSymbol(name, true);

  // Symbol
  const prefixArray = varPrefix && varPrefix !== "" ? [varPrefix] : [];
  const varSymbol = [...prefixArray, varTypePrefix, nameShortSymbol].join(DELIMITER_VAR_SYMBOL);
  return varSymbol.toUpperCase();
};

//
// Export
// ----------------------------------------------------------------------------

const GraphUtil = {
  //
  toReactFlowFormat,

  // Node
  nextNodeId,
  prepareNewNode,

  // Node : Handle
  toHandleUid,
  nextHandleId,

  // Edge
  toEdgeId,

  // Parameter : Var
  toParamVarSymbol,
};

export default GraphUtil;
