import { combineReducers } from "redux";

// Error Reducer
import ErrorReducer from "../app/error/ErrorReducer";

// Auth Reducer
import { AuthReducer } from "./auth/authSlice";

// Graph Reducers
import { EdgeReducer } from "./graph/edgeSlice";
import { EquationReducer } from "./graph/equationSlice";
import { GraphReducer } from "./graph/graphSlice";
import { NodeReducer } from "./graph/nodeSlice";
import { ElementReducer } from "./graph/elementSlice";

import { ParameterReducer } from "./graph/parameterSlice";

// Project Reducer
import { ProjectReducer } from "./project/projectSlice";
import { ProjectFileReducer } from "./project/projectFileSlice";

// Project :: Opt Reducers
import { OptSetupReducer } from "./project/optSetupSlice";
import { OptRunReducer } from "./project/optRunSlice";
import { OptRunJobReducer } from "./project/optRunJobSlice";

// User
import { UserReducer } from "./user/userSlice";

//
// Root Reducer definition

export const RootReducer = combineReducers({
  // Error
  error: ErrorReducer,

  // Auth Reducer
  auth: AuthReducer,

  // Graph Reducers
  edge: EdgeReducer,
  equation: EquationReducer,
  graph: GraphReducer,
  node: NodeReducer,
  parameter: ParameterReducer,
  element: ElementReducer,

  // Project  Reducer
  project: ProjectReducer,
  projectFile: ProjectFileReducer,

  // Project :: OptSetup Reducers
  optSetup: OptSetupReducer,

  // Project :: OptRun Reducers
  optRun: OptRunReducer,
  optRunJob: OptRunJobReducer,

  // User
  user: UserReducer,
});
