import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

// App Theme
import getTheme from "../assests/themes/AppThemes";

// Constants
import { DataPreLoadKeys, LocalStorageKeys } from "../constants/GeneralConstants";

// Utils
import { isTokenExpired } from "./auth/AuthUtil";
import { getItem } from "./LocalStorage";

// Urls
import PageURL from "../constants/pageUrls";

// Helper Functions
function _isUserAuthenticated(user) {
  if (Object.keys(user).length < 1) {
    return false;
  }
  const userPrivileges = user.privileges || [];

  // TODO : For Now userPriviliges.length >= 0 because its empty.
  const isUserAuthenticated = user.token && userPrivileges.length >= 0 && !isTokenExpired(user.token);
  return isUserAuthenticated;
}

/**
 * Routes which needs the USER Authenticated.
 */
function ProtectedRoute({ children }) {
  // Location
  const { pathname } = useLocation();

  // Get Authenticated User from local storage
  const user = JSON.parse(getItem(LocalStorageKeys.AUTH_USER)) || {};

  // State
  // const dataPreLoading = useSelector((state) => state.dataPreLoad.dataPreLoading);
  const dataPreLoading = false;

  // Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    //TODO : Clear error state in reducer here

    // Checking For Pre Loaded Data In Local Storage
    Object.keys(DataPreLoadKeys).map((eachKey) => {
      // Getting pre loaded data from local storage
      const value = JSON.parse(getItem(eachKey) || '""');

      /** If user is authenticated,
      then it will check whether the corresponding data is already present in localstorage or not, 
      if the data is not present, then that api is called and data is stored in local storage **/
      if (_isUserAuthenticated(user) && (!value || Object.values(value).length === 0)) {
        // dispatch data fetch
      }
    });
  }, [dispatch, pathname]);

  //If user is not authenticated
  if (!_isUserAuthenticated(user)) {
    localStorage.clear();
    return <Navigate to={PageURL.LoginPageURL} />;
  }

  //Loader
  if (dataPreLoading) {
    // return <DataPreLoader />;
  }

  // Theme
  const themeObj = getTheme();
  const themeId = themeObj?.id;

  return (
    <>
      {/** Pass the current themeId to children */}
      {React.cloneElement(children, { themeId: themeId })}
    </>
  );
}

export default ProtectedRoute;
