/**
 * ReactFlow :: Graph Configuration
 */

// Custom Edges
import AssociationEdge from "./edge/AssociationEdge";
import FlowEdge from "./edge/FlowEdge";
import SimpleEdge from "./edge/SimpleEdge";

// Custom Nodes
import ConservationNode from "./node/ConservationNode";
import ControlVolumeNode from "./node/ControlVolumeNode";
import SimpleNode from "./node/SimpleNode";
import SinkNode from "./node/SinkNode";
import SourceNode from "./node/SourceNode";

//
// Math
// ----------------------------------------------------------------------------

// Operator : Compare
const CompareOperator = {
  EQ: "=",

  NOT_EQ: "!=",

  GT: ">",
  GT_EQ: ">=",

  LT: "<",
  LT_EQ: "<=",
};

//
// Variable
// ----------------------------------------------------------------------------

// Type
const VarType = {
  MASS: "MASS",
  ENERGY_THERMAL: "ENERGY_THERMAL",
  ENERGY_ELECTRICAL: "ENERGY_ELECTRICAL",
  PARAMETER: "PARAMETER",
  OTHER: "OTHER",
};

const VarTypeNameMap = {
  [VarType.MASS]: "Mass",
  [VarType.ENERGY_THERMAL]: "Thermal Energy",
  [VarType.ENERGY_ELECTRICAL]: "Electrical Energy",
  [VarType.PARAMETER]: "Parameter",
  [VarType.OTHER]: "Other",
};

const VarTypeColorsMap = {
  [VarType.MASS]: "#4fb2ff",
  [VarType.ENERGY_THERMAL]: "#ffd400",
  [VarType.ENERGY_ELECTRICAL]: "#89dd39",
  [VarType.PARAMETER]: "#b1b1b7",
  [VarType.OTHER]: "#b1b1b7",
};

//
// Node
// ----------------------------------------------------------------------------

// Data Props
const NodeDataProp = {
  TYPE: "type",
  NAME: "name",
  SYMBOL: "symbol",
  PARAMETERS: "parameters",
  EQUATIONS: "equations",
  STYLE: "style",
};

// Type
const NodeType = {
  DEFAULT: "default",
  CONTROL_VOLUME: "control_volume",
  CONSERVATION: "conservation",
  SOURCE: "source",
  SINK: "sink",
};

const NodeTypeNameMap = {
  [NodeType.DEFAULT]: "--",
  [NodeType.CONTROL_VOLUME]: "Control Volume",
  [NodeType.CONSERVATION]: "Splitter",
  [NodeType.SOURCE]: "Source",
  [NodeType.SINK]: "Sink",
};

const NodeTypeColorsMap = {
  [NodeType.DEFAULT]: "#b1b1b7",
  [NodeType.CONTROL_VOLUME]: "#e9ecef",
  [NodeType.CONSERVATION]: "#e9ecef",
  [NodeType.SOURCE]: "#ebeeaa",
  [NodeType.SINK]: "#ffe1a8",
};

const NodeTypeComponentMap = {
  [NodeType.DEFAULT]: SimpleNode,
  [NodeType.CONTROL_VOLUME]: ControlVolumeNode,
  [NodeType.CONSERVATION]: ConservationNode,
  [NodeType.SOURCE]: SourceNode,
  [NodeType.SINK]: SinkNode,
};

//
// Edge
// ----------------------------------------------------------------------------

// Data Props
const EdgeDataProp = {
  TYPE: "type",
  NAME: "name",
  SYMBOL: "symbol",
  SOURCE: "source",
  TARGET: "target",
  STYLE: "style",
};

// Type
const EdgeType = {
  DEFAULT: "default",
  ASSOCIATION: "association",
  FLOW: "flow",
};

const EdgeTypeNameMap = {
  [EdgeType.DEFAULT]: "--",
  [EdgeType.ASSOCIATION]: "Association",
  [EdgeType.FLOW]: "Flow",
};

const EdgeTypeColorsMap = {
  [EdgeType.DEFAULT]: "#b1b1b7",
  [EdgeType.ASSOCIATION]: "#b1b1b7",
  [EdgeType.FLOW]: "#b1b1b7",
};

const EdgeTypeComponentMap = {
  [EdgeType.DEFAULT]: SimpleEdge,
  [EdgeType.ASSOCIATION]: AssociationEdge,
  [EdgeType.FLOW]: FlowEdge,
};

const EdgeVarTypes = [
  //
  [VarType.MASS],
  [VarType.ENERGY_ELECTRICAL],
  [VarType.ENERGY_THERMAL],
  [VarType.OTHER],
];

const ReactFlowElements = {
  NODE: "NODE",
  EDGE: "EDGE",
  PANEL: "PANEL",
  PANEL_ID: "PANEL_ID",
};

//
// Export
// ----------------------------------------------------------------------------

const GraphConfig = {
  // Math
  CompareOperator,

  // VarType
  VarType,
  VarTypeNameMap,
  VarTypeColorsMap,

  // Node
  NodeDataProp,
  NodeType,
  NodeTypeNameMap,
  NodeTypeColorsMap,
  NodeTypeComponentMap,

  // Edge
  EdgeDataProp,
  EdgeType,
  EdgeTypeNameMap,
  EdgeTypeColorsMap,
  EdgeTypeComponentMap,
  EdgeVarTypes,

  //React flow Elements
  ReactFlowElements,
};

export default GraphConfig;
